import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchReportsList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/reports', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchReportViews(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/report-views', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
